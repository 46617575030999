<template>
  <div class="detail-page-container">
    <h3 class="title">{{ detailData.title }}</h3>
    <div class="publish">
      <span>发布时间：{{ detailData.publishTime | formatDate }}</span>
      &nbsp;
      &nbsp;
      <span>查看次数
        <em class="el-icon-view" />
        {{ detailData.clickCount }}
      </span>
      &nbsp;
      <span>下载次数
        <em class="el-icon-download" />
        {{ detailData.downloadCount }}
      </span>
    </div>
    <div class="diving-line" />
    <div class="content" v-html="detailData.content"></div>
    <div class="file" style="display: flex; justify-content: flex-end; margin: 10px 0;" v-if="Array.isArray(detailData.fileVoList) && detailData.fileVoList.length > 0">
      <span>附件：</span>
      <div class="list" style="display: flex; flex-direction: column; gap: 10px;">
        <a v-for="item in detailData.fileVoList" :key="item.id" href="#" @click.stop="onDownload(item)">{{ item.fileName }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { siteNewsDetail } from '@/api'
import { validatenull } from '@/utils/validate'
import moment from 'moment'
import { downloadFileFromOss } from "@/api/list";

export default {
  name: 'DetailPage',
  filters: {
    formatDate (val) {
      if (validatenull(val)) return '--'
      return moment(val).format('yyyy-MM-DD')
    }
  },
  data () {
    return {
      detailData: {}
    }
  },
  activated () {
    this.siteNewsDetail()
  },
  created () {
    if (validatenull(this.$route.query.id)) {
      this.$router.go(-1)
    }
    this.siteNewsDetail()
  },
  methods: {
    onDownload (item) {
      this.$message.info("导出中，请稍等。。。");
      downloadFileFromOss(item.id).then(res => {
        this.downloadFileStream(res.data, item.fileName)
      })
    },
    downloadFileStream(file, name) {
      // 获取文件流后下载
      new Promise((resolute) => {
        const blob = new Blob([file]);
        const fileName = name;
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = fileName; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 模拟触发点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放blob
        resolute();
      }).then(() => {
        this.$message.success("导出成功!");
      });
    },
    siteNewsDetail () {
      siteNewsDetail(this.$route.query.id).then(({ data }) => {
        this.detailData = data.data
      })
    }
  }
}
</script>

<style lang="scss">
.diving-line {
  width: 10000px;
  height: 1px;
  border-bottom: 1px dashed #eee;
  transform: translateX(-50%);
}
.detail-page-container {
  width: 1020px;
  padding: 10px;
  .title {
    font-size: 20px;
    font-weight: normal;
    text-align: center;
    color: #333;
    padding-bottom: 10px;
    line-height: 28px;
  }
  .publish {
    margin: 10px 0;
  }
  .content {
    line-height: 28px;
    font-family: arial !important;
    font-size: 16px !important;
    color: #191818 !important;
    font-weight: lighter !important;
    text-align: justify;
    font, p, span {
      font-family: arial !important;
      font-size: 16px !important;
      color: #191818 !important;
      line-height: 40px !important;
      font-weight: lighter !important;
      text-align: justify;
    }
    //text-align: center;
    //p {
    //  span {
    //    font {
    //      img {
    //        width: 100%;
    //      }
    //    }
    //  }
    //}
  }
}
</style>
